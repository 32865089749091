export default [
  {
    capital: 'Sukhumi',
    continents: ['Europe', 'Asia'],
    largestCity: 'Sukhumi',
    name: 'Abkhazia',
    secondLargestCity: 'Gagra',
  },
  {
    capital: 'Kabul',
    continents: ['Asia'],
    largestCity: 'Kabul',
    name: 'Afghanistan',
    secondLargestCity: 'Kandahar',
  },
  {
    capital: 'Episkopi Cantonment',
    continents: ['Europe', 'Asia'],
    largestCity: 'Episkopi Cantonment',
    name: 'Akrotiri and Dhekelia',
    secondLargestCity: 'Ayios Nikolaos',
  },
  {
    capital: 'Mariehamn',
    continents: ['Europe'],
    largestCity: 'Mariehamn',
    name: 'Åland Islands',
    secondLargestCity: 'Jomala',
  },
  {
    capital: 'Tirana',
    continents: ['Europe'],
    largestCity: 'Tirana',
    name: 'Albania',
    secondLargestCity: 'Durrës',
  },
  {
    capital: 'Algiers',
    continents: ['Africa'],
    largestCity: 'Algiers',
    name: 'Algeria',
    secondLargestCity: 'Oran',
  },
  {
    capital: 'Pago Pago',
    continents: ['Oceania'],
    largestCity: 'Tafuna',
    name: 'American Samoa',
    secondLargestCity: "Nu'uuli",
  },
  {
    capital: 'Andorra la Vella',
    continents: ['Europe'],
    largestCity: 'Andorra la Vella',
    name: 'Andorra',
    secondLargestCity: 'Les Escaldes',
  },
  {
    capital: 'Luanda',
    continents: ['Africa'],
    largestCity: 'Luanda',
    name: 'Angola',
    secondLargestCity: 'Huambo',
  },
  {
    capital: 'The Valley',
    continents: ['America'],
    largestCity: 'North Side',
    name: 'Anguilla',
    secondLargestCity: 'The Valley',
  },
  {
    capital: "St. John's",
    continents: ['America'],
    largestCity: "St. John's",
    name: 'Antigua and Barbuda',
    secondLargestCity: 'All Saints',
  },
  {
    capital: 'Buenos Aires',
    continents: ['America'],
    largestCity: 'Buenos Aires',
    name: 'Argentina',
    secondLargestCity: 'Córdoba',
  },
  {
    capital: 'Yerevan',
    continents: ['Europe', 'Asia'],
    largestCity: 'Yerevan',
    name: 'Armenia',
    secondLargestCity: 'Gyumri',
  },
  {
    capital: 'Stepanakert',
    continents: ['Europe', 'Asia'],
    largestCity: 'Stepanakert',
    name: 'Artsakh',
    secondLargestCity: 'Shusha',
  },
  {
    capital: 'Oranjestad',
    continents: ['America'],
    largestCity: 'Oranjestad',
    name: 'Aruba',
    secondLargestCity: 'Sint Nicolaas',
  },
  {
    capital: 'Canberra',
    continents: ['Oceania'],
    largestCity: 'Sydney',
    name: 'Australia',
    secondLargestCity: 'Melbourne',
  },
  {
    capital: 'Vienna',
    continents: ['Europe'],
    largestCity: 'Vienna',
    name: 'Austria',
    secondLargestCity: 'Graz',
  },
  {
    capital: 'Baku',
    continents: ['Europe', 'Asia'],
    largestCity: 'Baku',
    name: 'Azerbaijan',
    secondLargestCity: 'Ganja',
  },
  {
    capital: 'Nassau',
    continents: ['America'],
    largestCity: 'Nassau',
    name: 'Bahamas',
    secondLargestCity: 'Freeport',
  },
  {
    capital: 'Manama',
    continents: ['Asia'],
    largestCity: 'Manama',
    name: 'Bahrain',
    secondLargestCity: 'Riffa',
  },
  {
    capital: 'Dhaka',
    continents: ['Asia'],
    largestCity: 'Dhaka',
    name: 'Bangladesh',
    secondLargestCity: 'Chittagong',
  },
  {
    capital: 'Bridgetown',
    continents: ['America'],
    largestCity: 'Bridgetown',
    name: 'Barbados',
    secondLargestCity: 'Speightstown',
  },
  {
    capital: 'Minsk',
    continents: ['Europe'],
    largestCity: 'Minsk',
    name: 'Belarus',
    secondLargestCity: 'Homel',
  },
  {
    capital: 'Brussels',
    continents: ['Europe'],
    largestCity: 'Brussels',
    name: 'Belgium',
    secondLargestCity: 'Antwerp',
  },
  {
    capital: 'Belmopan',
    continents: ['America'],
    largestCity: 'Belize City',
    name: 'Belize',
    secondLargestCity: 'San Ignacio',
  },
  {
    capital: 'Porto-Novo',
    continents: ['Africa'],
    largestCity: 'Cotonou',
    name: 'Benin',
    secondLargestCity: 'Abomey-Calavi',
  },
  {
    capital: 'Hamilton',
    continents: ['America'],
    largestCity: "St. George's",
    name: 'Bermuda',
    secondLargestCity: 'Hamilton',
  },
  {
    capital: 'Thimphu',
    continents: ['Asia'],
    largestCity: 'Thimphu',
    name: 'Bhutan',
    secondLargestCity: 'Phuntsholing',
  },
  {
    capital: 'Sucre, La Paz',
    continents: ['America'],
    largestCity: 'Santa Cruz de la Sierra',
    name: 'Bolivia',
    secondLargestCity: 'El Alto',
  },
  {
    capital: 'Sarajevo',
    continents: ['Europe'],
    largestCity: 'Sarajevo',
    name: 'Bosnia and Herzegovina',
    secondLargestCity: 'Banja Luka',
  },
  {
    capital: 'Gaborone',
    continents: ['Africa'],
    largestCity: 'Gaborone',
    name: 'Botswana',
    secondLargestCity: 'Francistown',
  },
  {
    capital: 'Brasília',
    continents: ['America'],
    largestCity: 'São Paulo',
    name: 'Brazil',
    secondLargestCity: 'Rio de Janeiro',
  },
  {
    capital: 'Bandar Seri Begawan',
    continents: ['Asia'],
    largestCity: 'Bandar Seri Begawan',
    name: 'Brunei',
    secondLargestCity: 'Kuala Belait',
  },
  {
    capital: 'Sofia',
    continents: ['Europe'],
    largestCity: 'Sofia',
    name: 'Bulgaria',
    secondLargestCity: 'Plovdiv',
  },
  {
    capital: 'Ouagadougou',
    continents: ['Africa'],
    largestCity: 'Ouagadougou',
    name: 'Burkina Faso',
    secondLargestCity: 'Bobo-Dioulasso',
  },
  {
    capital: 'Bujumbura',
    continents: ['Africa'],
    largestCity: 'Bujumbura',
    name: 'Burundi',
    secondLargestCity: 'Muyinga',
  },
  {
    capital: 'Phnom Penh',
    continents: ['Asia'],
    largestCity: 'Phnom Penh',
    name: 'Cambodia',
    secondLargestCity: 'Siem Reap',
  },
  {
    capital: 'Yaoundé',
    continents: ['Africa'],
    largestCity: 'Douala',
    name: 'Cameroon',
    secondLargestCity: 'Yaoundé',
  },
  {
    capital: 'Ottawa',
    continents: ['America'],
    largestCity: 'Toronto',
    name: 'Canada',
    secondLargestCity: 'Montreal',
  },
  {
    capital: 'Praia',
    continents: ['Africa'],
    largestCity: 'Praia',
    name: 'Cape Verde',
    secondLargestCity: 'Mindelo',
  },
  {
    capital: 'George Town',
    continents: ['America'],
    largestCity: 'George Town',
    name: 'Cayman Islands',
    secondLargestCity: 'Bodden Town',
  },
  {
    capital: 'Bangui',
    continents: ['Africa'],
    largestCity: 'Bangui',
    name: 'Central African Republic',
    secondLargestCity: 'Bimbo',
  },
  {
    capital: "N'Djamena",
    continents: ['Africa'],
    largestCity: "N'Djamena",
    name: 'Chad',
    secondLargestCity: 'Moundou',
  },
  {
    capital: 'Santiago',
    continents: ['America'],
    largestCity: 'Santiago',
    name: 'Chile',
    secondLargestCity: 'Puente Alto',
  },
  {
    capital: 'Beijing',
    continents: ['Asia'],
    largestCity: 'Shanghai',
    name: "People's Republic of China",
    secondLargestCity: 'Beijing',
  },
  {
    capital: 'Flying Fish Cove',
    continents: ['Asia'],
    largestCity: 'Flying Fish Cove',
    name: 'Christmas Island',
    secondLargestCity: 'Poon Saan',
  },
  {
    capital: 'West Island',
    continents: ['Asia'],
    largestCity: 'Bantam',
    name: 'Cocos (Keeling) Islands',
    secondLargestCity: 'West Island',
  },
  {
    capital: 'Bogotá',
    continents: ['America'],
    largestCity: 'Bogotá',
    name: 'Colombia',
    secondLargestCity: 'Medellín',
  },
  {
    capital: 'Moroni',
    continents: ['Africa'],
    largestCity: 'Moroni',
    name: 'Comoros',
    secondLargestCity: 'Mutsamudu',
  },
  {
    capital: 'Kinshasa',
    continents: ['Africa'],
    largestCity: 'Kinshasa',
    name: 'Democratic Republic of the Congo',
    secondLargestCity: 'Lubumbashi',
  },
  {
    capital: 'Brazzaville',
    continents: ['Africa'],
    largestCity: 'Brazzaville',
    name: 'Republic of the Congo',
    secondLargestCity: 'Pointe-Noire',
  },
  {
    capital: 'Avarua',
    continents: ['Oceania'],
    largestCity: 'Avarua',
    name: 'Cook Islands',
    secondLargestCity: 'Amuri',
  },
  {
    capital: 'San José',
    continents: ['America'],
    largestCity: 'San José',
    name: 'Costa Rica',
    secondLargestCity: 'Alajuela',
  },
  {
    capital: 'Yamoussoukro',
    continents: ['Africa'],
    largestCity: 'Abidjan',
    name: "Côte d'Ivoire",
    secondLargestCity: 'Bouaké',
  },
  {
    capital: 'Zagreb',
    continents: ['Europe'],
    largestCity: 'Zagreb',
    name: 'Croatia',
    secondLargestCity: 'Split',
  },
  {
    capital: 'Havana',
    continents: ['America'],
    largestCity: 'Havana',
    name: 'Cuba',
    secondLargestCity: 'Santiago de Cuba',
  },
  {
    capital: 'Willemstad',
    continents: ['America'],
    largestCity: 'Willemstad',
    name: 'Curaçao',
    secondLargestCity: 'Sint Michiel',
  },
  {
    capital: 'Nicosia',
    continents: ['Europe', 'Asia'],
    largestCity: 'Nicosia',
    name: 'Cyprus',
    secondLargestCity: 'Limassol',
  },
  {
    capital: 'Prague',
    continents: ['Europe'],
    largestCity: 'Prague',
    name: 'Czech Republic',
    secondLargestCity: 'Brno',
  },
  {
    capital: 'Copenhagen',
    continents: ['Europe'],
    largestCity: 'Copenhagen',
    name: 'Denmark',
    secondLargestCity: 'Aarhus',
  },
  {
    capital: 'Djibouti',
    continents: ['Africa'],
    largestCity: 'Djibouti',
    name: 'Djibouti',
    secondLargestCity: 'Ali Sabieh',
  },
  {
    capital: 'Roseau',
    continents: ['America'],
    largestCity: 'Roseau',
    name: 'Dominica',
    secondLargestCity: 'Portsmouth',
  },
  {
    capital: 'Santo Domingo',
    continents: ['America'],
    largestCity: 'Santo Domingo',
    name: 'Dominican Republic',
    secondLargestCity: 'Santiago de los Caballeros',
  },
  {
    capital: 'Dili',
    continents: ['Asia'],
    largestCity: 'Dili',
    name: 'East Timor',
    secondLargestCity: 'Dare',
  },
  {
    capital: 'Quito',
    continents: ['America'],
    largestCity: 'Guayaquil',
    name: 'Ecuador',
    secondLargestCity: 'Quito',
  },
  {
    capital: 'Cairo',
    continents: ['Asia', 'Africa'],
    largestCity: 'Cairo',
    name: 'Egypt',
    secondLargestCity: 'Alexandria',
  },
  {
    capital: 'San Salvador',
    continents: ['America'],
    largestCity: 'San Salvador',
    name: 'El Salvador',
    secondLargestCity: 'Santa Ana',
  },
  {
    capital: 'Malabo',
    continents: ['Africa'],
    largestCity: 'Bata',
    name: 'Equatorial Guinea',
    secondLargestCity: 'Malabo',
  },
  {
    capital: 'Asmara',
    continents: ['Africa'],
    largestCity: 'Asmara',
    name: 'Eritrea',
    secondLargestCity: 'Keren',
  },
  {
    capital: 'Tallinn',
    continents: ['Europe'],
    largestCity: 'Tallinn',
    name: 'Estonia',
    secondLargestCity: 'Tartu',
  },
  {
    capital: 'Addis Ababa',
    continents: ['Africa'],
    largestCity: 'Addis Ababa',
    name: 'Ethiopia',
    secondLargestCity: 'Dire Dawa',
  },
  {
    capital: 'Stanley',
    continents: ['America'],
    largestCity: 'Stanley',
    name: 'Falkland Islands',
    secondLargestCity: 'Goose Green',
  },
  {
    capital: 'Tórshavn',
    continents: ['Europe'],
    largestCity: 'Tórshavn',
    name: 'Faroe Islands',
    secondLargestCity: 'Klaksvík',
  },
  {
    capital: 'Suva',
    continents: ['Oceania'],
    largestCity: 'Nasinu',
    name: 'Fiji',
    secondLargestCity: 'Suva',
  },
  {
    capital: 'Helsinki',
    continents: ['Europe'],
    largestCity: 'Helsinki',
    name: 'Finland',
    secondLargestCity: 'Espoo',
  },
  {
    capital: 'Paris',
    continents: ['Europe'],
    largestCity: 'Paris',
    name: 'France',
    secondLargestCity: 'Marseille',
  },
  {
    capital: 'Cayenne',
    continents: ['America'],
    largestCity: 'Cayenne',
    name: 'French Guiana',
    secondLargestCity: 'Saint-Laurent-du-Maroni',
  },
  {
    capital: 'Papeete',
    continents: ['Oceania'],
    largestCity: 'Papeete',
    name: 'French Polynesia',
    secondLargestCity: 'Mahina',
  },
  {
    capital: 'Libreville',
    continents: ['Africa'],
    largestCity: 'Libreville',
    name: 'Gabon',
    secondLargestCity: 'Port-Gentil',
  },
  {
    capital: 'Banjul',
    continents: ['Africa'],
    largestCity: 'Serekunda',
    name: 'The Gambia',
    secondLargestCity: 'Brikama',
  },
  {
    capital: 'Tbilisi',
    continents: ['Europe', 'Asia'],
    largestCity: 'Tbilisi',
    name: 'Georgia',
    secondLargestCity: 'Batumi',
  },
  {
    capital: 'Berlin',
    continents: ['Europe'],
    largestCity: 'Berlin',
    name: 'Germany',
    secondLargestCity: 'Hamburg',
  },
  {
    capital: 'Accra',
    continents: ['Africa'],
    largestCity: 'Accra',
    name: 'Ghana',
    secondLargestCity: 'Kumasi',
  },
  {
    capital: 'Gibraltar',
    continents: ['Europe'],
    largestCity: 'Gibraltar',
    name: 'Gibraltar',
    secondLargestCity: '',
  },
  {
    capital: 'Athens',
    continents: ['Europe'],
    largestCity: 'Athens',
    name: 'Greece',
    secondLargestCity: 'Thessaloniki',
  },
  {
    capital: 'Nuuk',
    continents: ['America'],
    largestCity: 'Nuuk',
    name: 'Greenland',
    secondLargestCity: 'Sisimiut',
  },
  {
    capital: "St. George's",
    continents: ['America'],
    largestCity: "St. George's",
    name: 'Grenada',
    secondLargestCity: 'Gouyave',
  },
  {
    capital: 'Hagåtña',
    continents: ['Oceania'],
    largestCity: 'Tamuning',
    name: 'Guam',
    secondLargestCity: 'Mangilao',
  },
  {
    capital: 'Guatemala City',
    continents: ['America'],
    largestCity: 'Guatemala City',
    name: 'Guatemala',
    secondLargestCity: 'Quetzaltenango',
  },
  {
    capital: 'Saint Peter Port',
    continents: ['Europe'],
    largestCity: 'Saint Peter Port',
    name: 'Guernsey',
    secondLargestCity: 'Vale',
  },
  {
    capital: 'Conakry',
    continents: ['Africa'],
    largestCity: 'Conakry',
    name: 'Guinea',
    secondLargestCity: 'Nzérékoré',
  },
  {
    capital: 'Bissau',
    continents: ['Africa'],
    largestCity: 'Bissau',
    name: 'Guinea-Bissau',
    secondLargestCity: 'Gabú',
  },
  {
    capital: 'Georgetown',
    continents: ['America'],
    largestCity: 'Georgetown',
    name: 'Guyana',
    secondLargestCity: 'Linden',
  },
  {
    capital: 'Port-au-Prince',
    continents: ['America'],
    largestCity: 'Port-au-Prince',
    name: 'Haiti',
    secondLargestCity: 'Gonaïves',
  },
  {
    capital: 'Tegucigalpa',
    continents: ['America'],
    largestCity: 'Tegucigalpa',
    name: 'Honduras',
    secondLargestCity: 'San Pedro Sula',
  },
  {
    capital: 'Budapest',
    continents: ['Europe'],
    largestCity: 'Budapest',
    name: 'Hungary',
    secondLargestCity: 'Debrecen',
  },
  {
    capital: 'Reykjavík',
    continents: ['Europe'],
    largestCity: 'Reykjavík',
    name: 'Iceland',
    secondLargestCity: 'Kópavogur',
  },
  {
    capital: 'New Delhi',
    continents: ['Asia'],
    largestCity: 'Mumbai',
    name: 'India',
    secondLargestCity: 'Delhi',
  },
  {
    capital: 'Jakarta',
    continents: ['Asia'],
    largestCity: 'Jakarta',
    name: 'Indonesia',
    secondLargestCity: 'Surabaya',
  },
  {
    capital: 'Tehran',
    continents: ['Asia'],
    largestCity: 'Tehran',
    name: 'Iran',
    secondLargestCity: 'Mashhad',
  },
  {
    capital: 'Baghdad',
    continents: ['Asia'],
    largestCity: 'Baghdad',
    name: 'Iraq',
    secondLargestCity: 'Basra',
  },
  {
    capital: 'Dublin',
    continents: ['Europe'],
    largestCity: 'Dublin',
    name: 'Ireland',
    secondLargestCity: 'Cork',
  },
  {
    capital: 'Douglas',
    continents: ['Europe'],
    largestCity: 'Douglas',
    name: 'Isle of Man',
    secondLargestCity: 'Onchan',
  },
  {
    capital: 'Jerusalem',
    continents: ['Asia'],
    largestCity: 'Jerusalem',
    name: 'Israel',
    secondLargestCity: 'Tel Aviv',
  },
  {
    capital: 'Rome',
    continents: ['Europe'],
    largestCity: 'Rome',
    name: 'Italy',
    secondLargestCity: 'Milan',
  },
  {
    capital: 'Kingston',
    continents: ['America'],
    largestCity: 'Kingston',
    name: 'Jamaica',
    secondLargestCity: 'Spanish Town',
  },
  {
    capital: 'Tokyo',
    continents: ['Asia'],
    largestCity: 'Tokyo',
    name: 'Japan',
    secondLargestCity: 'Yokohama',
  },
  {
    capital: 'Saint Helier',
    continents: ['Europe'],
    largestCity: 'Saint Helier',
    name: 'Jersey',
    secondLargestCity: 'Saint Saviour',
  },
  {
    capital: 'Amman',
    continents: ['Asia'],
    largestCity: 'Amman',
    name: 'Jordan',
    secondLargestCity: 'Zarqa',
  },
  {
    capital: 'Astana',
    continents: ['Europe', 'Asia'],
    largestCity: 'Almaty',
    name: 'Kazakhstan',
    secondLargestCity: 'Astana',
  },
  {
    capital: 'Nairobi',
    continents: ['Africa'],
    largestCity: 'Nairobi',
    name: 'Kenya',
    secondLargestCity: 'Mombasa',
  },
  {
    capital: 'Tarawa',
    continents: ['Oceania'],
    largestCity: 'Betio',
    name: 'Kiribati',
    secondLargestCity: 'Bairiki',
  },
  {
    capital: 'Pyongyang',
    continents: ['Asia'],
    largestCity: 'Pyongyang',
    name: 'North Korea',
    secondLargestCity: 'Hamhung',
  },
  {
    capital: 'Seoul',
    continents: ['Asia'],
    largestCity: 'Seoul',
    name: 'South Korea',
    secondLargestCity: 'Busan',
  },
  {
    capital: 'Pristina',
    continents: ['Europe'],
    largestCity: 'Pristina',
    name: 'Kosovo',
    secondLargestCity: 'Prizren',
  },
  {
    capital: 'Kuwait City',
    continents: ['Asia'],
    largestCity: 'Kuwait City',
    name: 'Kuwait',
    secondLargestCity: 'Al Jahra',
  },
  {
    capital: 'Bishkek',
    continents: ['Asia'],
    largestCity: 'Bishkek',
    name: 'Kyrgyzstan',
    secondLargestCity: 'Osh',
  },
  {
    capital: 'Vientiane',
    continents: ['Asia'],
    largestCity: 'Vientiane',
    name: 'Laos',
    secondLargestCity: 'Pakxe',
  },
  {
    capital: 'Riga',
    continents: ['Europe'],
    largestCity: 'Riga',
    name: 'Latvia',
    secondLargestCity: 'Daugavpils',
  },
  {
    capital: 'Beirut',
    continents: ['Asia'],
    largestCity: 'Beirut',
    name: 'Lebanon',
    secondLargestCity: 'Tripoli',
  },
  {
    capital: 'Maseru',
    continents: ['Africa'],
    largestCity: 'Maseru',
    name: 'Lesotho',
    secondLargestCity: 'Teyateyaneng',
  },
  {
    capital: 'Monrovia',
    continents: ['Africa'],
    largestCity: 'Monrovia',
    name: 'Liberia',
    secondLargestCity: 'Gbarnga',
  },
  {
    capital: 'Tripoli',
    continents: ['Africa'],
    largestCity: 'Tripoli',
    name: 'Libya',
    secondLargestCity: 'Benghazi',
  },
  {
    capital: 'Vaduz',
    continents: ['Europe'],
    largestCity: 'Schaan',
    name: 'Liechtenstein',
    secondLargestCity: 'Vaduz',
  },
  {
    capital: 'Vilnius',
    continents: ['Europe'],
    largestCity: 'Vilnius',
    name: 'Lithuania',
    secondLargestCity: 'Kaunas',
  },
  {
    capital: 'Luxembourg City',
    continents: ['Europe'],
    largestCity: 'Luxembourg City',
    name: 'Luxembourg',
    secondLargestCity: 'Esch-sur-Alzette',
  },
  {
    capital: 'Skopje',
    continents: ['Europe'],
    largestCity: 'Skopje',
    name: 'Macedonia',
    secondLargestCity: 'Bitola',
  },
  {
    capital: 'Antananarivo',
    continents: ['Africa'],
    largestCity: 'Antananarivo',
    name: 'Madagascar',
    secondLargestCity: 'Toamasina',
  },
  {
    capital: 'Lilongwe',
    continents: ['Africa'],
    largestCity: 'Lilongwe',
    name: 'Malawi',
    secondLargestCity: 'Blantyre',
  },
  {
    capital: 'Kuala Lumpur',
    continents: ['Asia'],
    largestCity: 'Kuala Lumpur',
    name: 'Malaysia',
    secondLargestCity: 'Penang',
  },
  {
    capital: 'Malé',
    continents: ['Asia'],
    largestCity: 'Malé',
    name: 'Maldives',
    secondLargestCity: 'Addu City',
  },
  {
    capital: 'Bamako',
    continents: ['Africa'],
    largestCity: 'Bamako',
    name: 'Mali',
    secondLargestCity: 'Sikasso',
  },
  {
    capital: 'Valletta',
    continents: ['Europe'],
    largestCity: 'Birkirkara',
    name: 'Malta',
    secondLargestCity: 'Mosta',
  },
  {
    capital: 'Majuro',
    continents: ['Oceania'],
    largestCity: 'Ebeye',
    name: 'Marshall Islands',
    secondLargestCity: 'Rita',
  },
  {
    capital: 'Nouakchott',
    continents: ['Africa'],
    largestCity: 'Nouakchott',
    name: 'Mauritania',
    secondLargestCity: 'Nouadhibou',
  },
  {
    capital: 'Port Louis',
    continents: ['Africa'],
    largestCity: 'Port Louis',
    name: 'Mauritius',
    secondLargestCity: 'Beau Bassin-Rose Hill',
  },
  {
    capital: 'Mamoudzou',
    continents: ['Africa'],
    largestCity: 'Mamoudzou',
    name: 'Mayotte',
    secondLargestCity: 'Dzaoudzi',
  },
  {
    capital: 'Mexico City',
    continents: ['America'],
    largestCity: 'Mexico City',
    name: 'Mexico',
    secondLargestCity: 'Ecatepec',
  },
  {
    capital: 'Palikir',
    continents: ['Oceania'],
    largestCity: 'Weno',
    name: 'Federated States of Micronesia',
    secondLargestCity: 'Palikir',
  },
  {
    capital: 'Chişinău',
    continents: ['Europe'],
    largestCity: 'Chişinău',
    name: 'Moldova',
    secondLargestCity: 'Tiraspol',
  },
  {
    capital: 'Monaco',
    continents: ['Europe'],
    largestCity: 'Monte Carlo',
    name: 'Monaco',
    secondLargestCity: 'La Condamine',
  },
  {
    capital: 'Ulaanbaatar',
    continents: ['Asia'],
    largestCity: 'Ulaanbaatar',
    name: 'Mongolia',
    secondLargestCity: 'Darkhan',
  },
  {
    capital: 'Podgorica',
    continents: ['Europe'],
    largestCity: 'Podgorica',
    name: 'Montenegro',
    secondLargestCity: 'Nikšić',
  },
  {
    capital: 'Brades',
    continents: ['America'],
    largestCity: 'Brades',
    name: 'Montserrat',
    secondLargestCity: "Saint Peter's",
  },
  {
    capital: 'Rabat',
    continents: ['Africa'],
    largestCity: 'Casablanca',
    name: 'Morocco',
    secondLargestCity: 'Fez',
  },
  {
    capital: 'Maputo',
    continents: ['Africa'],
    largestCity: 'Maputo',
    name: 'Mozambique',
    secondLargestCity: 'Beira',
  },
  {
    capital: 'Nay Pyi Taw',
    continents: ['Asia'],
    largestCity: 'Yangon',
    name: 'Myanmar',
    secondLargestCity: 'Mandalay',
  },
  {
    capital: 'Windhoek',
    continents: ['Africa'],
    largestCity: 'Windhoek',
    name: 'Namibia',
    secondLargestCity: 'Rundu',
  },
  {
    capital: 'Yaren',
    continents: ['Oceania'],
    largestCity: 'Yaren',
    name: 'Nauru',
    secondLargestCity: 'Denigomudu',
  },
  {
    capital: 'Kathmandu',
    continents: ['Asia'],
    largestCity: 'Kathmandu',
    name: 'Nepal',
    secondLargestCity: 'Pokhara',
  },
  {
    capital: 'Amsterdam',
    continents: ['Europe'],
    largestCity: 'Amsterdam',
    name: 'Netherlands',
    secondLargestCity: 'Rotterdam',
  },
  {
    capital: 'Nouméa',
    continents: ['Oceania'],
    largestCity: 'Nouméa',
    name: 'New Caledonia',
    secondLargestCity: 'Wé',
  },
  {
    capital: 'Wellington',
    continents: ['Oceania'],
    largestCity: 'Auckland',
    name: 'New Zealand',
    secondLargestCity: 'Wellington',
  },
  {
    capital: 'Managua',
    continents: ['America'],
    largestCity: 'Managua',
    name: 'Nicaragua',
    secondLargestCity: 'León',
  },
  {
    capital: 'Niamey',
    continents: ['Africa'],
    largestCity: 'Niamey',
    name: 'Niger',
    secondLargestCity: 'Zinder',
  },
  {
    capital: 'Abuja',
    continents: ['Africa'],
    largestCity: 'Lagos',
    name: 'Nigeria',
    secondLargestCity: 'Kano',
  },
  {
    capital: 'Alofi',
    continents: ['Oceania'],
    largestCity: 'Alofi',
    name: 'Niue',
    secondLargestCity: 'Hakupu',
  },
  {
    capital: 'Kingston',
    continents: ['Oceania'],
    largestCity: 'Burnt Pine',
    name: 'Norfolk Island',
    secondLargestCity: 'Kingston',
  },
  {
    capital: 'North Nicosia',
    continents: ['Europe', 'Asia'],
    largestCity: 'North Nicosia',
    name: 'Northern Cyprus',
    secondLargestCity: 'Famagusta',
  },
  {
    capital: 'San Antonio',
    continents: ['Oceania'],
    largestCity: 'San Antonio',
    name: 'Northern Mariana Islands',
    secondLargestCity: 'Garapan',
  },
  {
    capital: 'Oslo',
    continents: ['Europe'],
    largestCity: 'Oslo',
    name: 'Norway',
    secondLargestCity: 'Bergen',
  },
  {
    capital: 'Muscat',
    continents: ['Asia'],
    largestCity: 'Muscat',
    name: 'Oman',
    secondLargestCity: 'Salalah',
  },
  {
    capital: 'Islamabad',
    continents: ['Asia'],
    largestCity: 'Karachi',
    name: 'Pakistan',
    secondLargestCity: 'Lahore',
  },
  {
    capital: 'Ngerulmud',
    continents: ['Oceania'],
    largestCity: 'Koror',
    name: 'Palau',
    secondLargestCity: 'Airai',
  },
  {
    capital: 'East Jerusalem',
    continents: ['Asia'],
    largestCity: 'Gaza City',
    name: 'State of Palestine',
    secondLargestCity: 'Hebron',
  },
  {
    capital: 'Panama City',
    continents: ['America'],
    largestCity: 'Panama City',
    name: 'Panama',
    secondLargestCity: 'Colón',
  },
  {
    capital: 'Port Moresby',
    continents: ['Oceania'],
    largestCity: 'Port Moresby',
    name: 'Papua New Guinea',
    secondLargestCity: 'Lae',
  },
  {
    capital: 'Asunción',
    continents: ['America'],
    largestCity: 'Asunción',
    name: 'Paraguay',
    secondLargestCity: 'Ciudad del Este',
  },
  {
    capital: 'Lima',
    continents: ['America'],
    largestCity: 'Lima',
    name: 'Peru',
    secondLargestCity: 'Arequipa',
  },
  {
    capital: 'Manila',
    continents: ['Asia'],
    largestCity: 'Quezon City',
    name: 'Philippines',
    secondLargestCity: 'Manila',
  },
  {
    capital: 'Adamstown',
    continents: ['Oceania'],
    largestCity: 'Adamstown',
    name: 'Pitcairn Islands',
    secondLargestCity: '',
  },
  {
    capital: 'Warsaw',
    continents: ['Europe'],
    largestCity: 'Warsaw',
    name: 'Poland',
    secondLargestCity: 'Kraków',
  },
  {
    capital: 'Lisbon',
    continents: ['Europe'],
    largestCity: 'Lisbon',
    name: 'Portugal',
    secondLargestCity: 'Vila Nova de Gaia',
  },
  {
    capital: 'San Juan',
    continents: ['America'],
    largestCity: 'San Juan',
    name: 'Puerto Rico',
    secondLargestCity: 'Bayamón',
  },
  {
    capital: 'Doha',
    continents: ['Asia'],
    largestCity: 'Doha',
    name: 'Qatar',
    secondLargestCity: 'Al Rayyan',
  },
  {
    capital: 'Bucharest',
    continents: ['Europe'],
    largestCity: 'Bucharest',
    name: 'Romania',
    secondLargestCity: 'Cluj-Napoca',
  },
  {
    capital: 'Moscow',
    continents: ['Europe', 'Asia'],
    largestCity: 'Moscow',
    name: 'Russia',
    secondLargestCity: 'Saint Petersburg',
  },
  {
    capital: 'Kigali',
    continents: ['Africa'],
    largestCity: 'Kigali',
    name: 'Rwanda',
    secondLargestCity: 'Muhanga',
  },
  {
    capital: 'Gustavia',
    continents: ['America'],
    largestCity: 'Gustavia',
    name: 'Saint Barthélemy',
    secondLargestCity: 'St. Jean',
  },
  {
    capital: 'Jamestown',
    continents: ['Africa'],
    largestCity: 'Half Tree Hollow',
    name: 'Saint Helena',
    secondLargestCity: 'Jamestown',
  },
  {
    capital: 'Basseterre',
    continents: ['America'],
    largestCity: 'Basseterre',
    name: 'Saint Kitts and Nevis',
    secondLargestCity: 'Charlestown',
  },
  {
    capital: 'Castries',
    continents: ['America'],
    largestCity: 'Castries',
    name: 'Saint Lucia',
    secondLargestCity: 'Gros Islet',
  },
  {
    capital: 'Marigot',
    continents: ['America'],
    largestCity: 'Marigot',
    name: 'Saint Martin',
    secondLargestCity: 'Rambaud',
  },
  {
    capital: 'Saint-Pierre',
    continents: ['America'],
    largestCity: 'Saint-Pierre',
    name: 'Saint Pierre and Miquelon',
    secondLargestCity: 'Miquelon',
  },
  {
    capital: 'Kingstown',
    continents: ['America'],
    largestCity: 'Kingstown',
    name: 'Saint Vincent and the Grenadines',
    secondLargestCity: 'Georgetown',
  },
  {
    capital: 'Apia',
    continents: ['Oceania'],
    largestCity: 'Apia',
    name: 'Samoa',
    secondLargestCity: 'Vaitele',
  },
  {
    capital: 'San Marino',
    continents: ['Europe'],
    largestCity: 'Dogana',
    name: 'San Marino',
    secondLargestCity: 'Borgo Maggiore',
  },
  {
    capital: 'São Tomé',
    continents: ['Africa'],
    largestCity: 'São Tomé',
    name: 'São Tomé and Príncipe',
    secondLargestCity: 'Trindade',
  },
  {
    capital: 'Riyadh',
    continents: ['Asia'],
    largestCity: 'Riyadh',
    name: 'Saudi Arabia',
    secondLargestCity: 'Jeddah',
  },
  {
    capital: 'Dakar',
    continents: ['Africa'],
    largestCity: 'Dakar',
    name: 'Senegal',
    secondLargestCity: 'Touba',
  },
  {
    capital: 'Belgrade',
    continents: ['Europe'],
    largestCity: 'Belgrade',
    name: 'Serbia',
    secondLargestCity: 'Novi Sad',
  },
  {
    capital: 'Victoria',
    continents: ['Africa'],
    largestCity: 'Victoria',
    name: 'Seychelles',
    secondLargestCity: 'Anse Etoile',
  },
  {
    capital: 'Freetown',
    continents: ['Africa'],
    largestCity: 'Freetown',
    name: 'Sierra Leone',
    secondLargestCity: 'Bo',
  },
  {
    capital: 'Singapore',
    continents: ['Asia'],
    largestCity: 'Singapore',
    name: 'Singapore',
    secondLargestCity: '',
  },
  {
    capital: 'Philipsburg',
    continents: ['America'],
    largestCity: "Lower Prince's Quarter",
    name: 'Sint Maarten',
    secondLargestCity: 'Cul De Sac',
  },
  {
    capital: 'Bratislava',
    continents: ['Europe'],
    largestCity: 'Bratislava',
    name: 'Slovakia',
    secondLargestCity: 'Košice',
  },
  {
    capital: 'Ljubljana',
    continents: ['Europe'],
    largestCity: 'Ljubljana',
    name: 'Slovenia',
    secondLargestCity: 'Maribor',
  },
  {
    capital: 'Honiara',
    continents: ['Oceania'],
    largestCity: 'Honiara',
    name: 'Solomon Islands',
    secondLargestCity: 'Gizo',
  },
  {
    capital: 'Mogadishu',
    continents: ['Africa'],
    largestCity: 'Mogadishu',
    name: 'Somalia',
    secondLargestCity: 'Hargeisa',
  },
  {
    capital: 'Hargeisa',
    continents: ['Africa'],
    largestCity: 'Hargeisa',
    name: 'Somaliland',
    secondLargestCity: 'Burao',
  },
  {
    capital: 'Pretoria',
    continents: ['Africa'],
    largestCity: 'Cape Town',
    name: 'South Africa',
    secondLargestCity: 'Durban',
  },
  {
    capital: 'King Edward Point',
    continents: ['America'],
    largestCity: 'King Edward Point',
    name: 'South Georgia and the South Sandwich Islands',
    secondLargestCity: 'Grytviken',
  },
  {
    capital: 'Tskhinvali',
    continents: ['Europe', 'Asia'],
    largestCity: 'Tskhinvali',
    name: 'South Ossetia',
    secondLargestCity: 'Akhalgori',
  },
  {
    capital: 'Juba',
    continents: ['Africa'],
    largestCity: 'Juba',
    name: 'South Sudan',
    secondLargestCity: 'Yei',
  },
  {
    capital: 'Madrid',
    continents: ['Europe'],
    largestCity: 'Madrid',
    name: 'Spain',
    secondLargestCity: 'Barcelona',
  },
  {
    capital: 'Sri Jayawardenepura Kotte',
    continents: ['Asia'],
    largestCity: 'Colombo',
    name: 'Sri Lanka',
    secondLargestCity: 'Dehiwala-Mount Lavinia',
  },
  {
    capital: 'Khartoum',
    continents: ['Africa'],
    largestCity: 'Omdurman',
    name: 'Sudan',
    secondLargestCity: 'Khartoum',
  },
  {
    capital: 'Paramaribo',
    continents: ['America'],
    largestCity: 'Paramaribo',
    name: 'Suriname',
    secondLargestCity: 'Lelydorp',
  },
  {
    capital: 'Longyearbyen',
    continents: ['Europe'],
    largestCity: 'Longyearbyen',
    name: 'Svalbard',
    secondLargestCity: 'Barentsburg',
  },
  {
    capital: 'Mbabane',
    continents: ['Africa'],
    largestCity: 'Manzini',
    name: 'Swaziland',
    secondLargestCity: 'Mbabane',
  },
  {
    capital: 'Stockholm',
    continents: ['Europe'],
    largestCity: 'Stockholm',
    name: 'Sweden',
    secondLargestCity: 'Gothenburg',
  },
  {
    capital: 'Bern',
    continents: ['Europe'],
    largestCity: 'Zürich',
    name: 'Switzerland',
    secondLargestCity: 'Geneva',
  },
  {
    capital: 'Damascus',
    continents: ['Asia'],
    largestCity: 'Aleppo',
    name: 'Syria',
    secondLargestCity: 'Damascus',
  },
  {
    capital: 'Taipei',
    continents: ['Asia'],
    largestCity: 'New Taipei',
    name: 'Taiwan',
    secondLargestCity: 'Taichung',
  },
  {
    capital: 'Dushanbe',
    continents: ['Asia'],
    largestCity: 'Dushanbe',
    name: 'Tajikistan',
    secondLargestCity: 'Khujand',
  },
  {
    capital: 'Dodoma',
    continents: ['Africa'],
    largestCity: 'Dar es Salaam',
    name: 'Tanzania',
    secondLargestCity: 'Mwanza',
  },
  {
    capital: 'Bangkok',
    continents: ['Asia'],
    largestCity: 'Bangkok',
    name: 'Thailand',
    secondLargestCity: 'Nonthaburi',
  },
  {
    capital: 'Lomé',
    continents: ['Africa'],
    largestCity: 'Lomé',
    name: 'Togo',
    secondLargestCity: 'Sokodé',
  },
  {
    capital: 'None',
    continents: ['Oceania'],
    largestCity: 'Fakaofo',
    name: 'Tokelau',
    secondLargestCity: 'Atafu',
  },
  {
    capital: 'Nukuʻalofa',
    continents: ['Oceania'],
    largestCity: 'Nukuʻalofa',
    name: 'Tonga',
    secondLargestCity: 'Neiafu',
  },
  {
    capital: 'Tiraspol',
    continents: ['Europe'],
    largestCity: 'Tiraspol',
    name: 'Transnistria',
    secondLargestCity: 'Tighina',
  },
  {
    capital: 'Port of Spain',
    continents: ['America'],
    largestCity: 'Chaguanas',
    name: 'Trinidad and Tobago',
    secondLargestCity: 'San Fernando',
  },
  {
    capital: 'Tunis',
    continents: ['Africa'],
    largestCity: 'Tunis',
    name: 'Tunisia',
    secondLargestCity: 'Sfax',
  },
  {
    capital: 'Ankara',
    continents: ['Europe', 'Asia'],
    largestCity: 'Istanbul',
    name: 'Turkey',
    secondLargestCity: 'Ankara',
  },
  {
    capital: 'Ashgabat',
    continents: ['Asia'],
    largestCity: 'Ashgabat',
    name: 'Turkmenistan',
    secondLargestCity: 'Türkmenabat',
  },
  {
    capital: 'Cockburn Town',
    continents: ['America'],
    largestCity: 'Five Cays',
    name: 'Turks and Caicos Islands',
    secondLargestCity: 'Honda Road',
  },
  {
    capital: 'Funafuti',
    continents: ['Oceania'],
    largestCity: 'Funafuti',
    name: 'Tuvalu',
    secondLargestCity: 'Asau',
  },
  {
    capital: 'Kampala',
    continents: ['Africa'],
    largestCity: 'Kampala',
    name: 'Uganda',
    secondLargestCity: 'Gulu',
  },
  {
    capital: 'Kiev',
    continents: ['Europe'],
    largestCity: 'Kiev',
    name: 'Ukraine',
    secondLargestCity: 'Kharkiv',
  },
  {
    capital: 'Abu Dhabi',
    continents: ['Asia'],
    largestCity: 'Dubai',
    name: 'United Arab Emirates',
    secondLargestCity: 'Abu Dhabi',
  },
  {
    capital: 'London',
    continents: ['Europe'],
    largestCity: 'London',
    name: 'United Kingdom',
    secondLargestCity: 'Birmingham',
  },
  {
    capital: 'Washington, D.C.',
    continents: ['America'],
    largestCity: 'New York City',
    name: 'United States of America',
    secondLargestCity: 'Los Angeles',
  },
  {
    capital: 'Montevideo',
    continents: ['America'],
    largestCity: 'Montevideo',
    name: 'Uruguay',
    secondLargestCity: 'Ciudad de la Costa',
  },
  {
    capital: 'Tashkent',
    continents: ['Asia'],
    largestCity: 'Tashkent',
    name: 'Uzbekistan',
    secondLargestCity: 'Namangan',
  },
  {
    capital: 'Port Vila',
    continents: ['Oceania'],
    largestCity: 'Port Vila',
    name: 'Vanuatu',
    secondLargestCity: 'Luganville',
  },
  {
    capital: 'Vatican City',
    continents: ['Europe'],
    largestCity: 'Vatican City',
    name: 'Vatican City',
    secondLargestCity: '',
  },
  {
    capital: 'Caracas',
    continents: ['America'],
    largestCity: 'Caracas',
    name: 'Venezuela',
    secondLargestCity: 'Maracaibo',
  },
  {
    capital: 'Hanoi',
    continents: ['Asia'],
    largestCity: 'Ho Chi Minh City',
    name: 'Vietnam',
    secondLargestCity: 'Hanoi',
  },
  {
    capital: 'Road Town',
    continents: ['America'],
    largestCity: 'Road Town',
    name: 'British Virgin Islands',
    secondLargestCity: 'Spanish Town',
  },
  {
    capital: 'Charlotte Amalie',
    continents: ['America'],
    largestCity: 'Charlotte Amalie',
    name: 'United States Virgin Islands',
    secondLargestCity: "Anna's Retreat",
  },
  {
    capital: 'Mata-Utu',
    continents: ['Oceania'],
    largestCity: 'Mata-Utu',
    name: 'Wallis and Futuna',
    secondLargestCity: 'Alele',
  },
  {
    capital: 'Laayoune',
    continents: ['Africa'],
    largestCity: 'Laayoune',
    name: 'Western Sahara',
    secondLargestCity: 'Dakhla',
  },
  {
    capital: "Sana'a",
    continents: ['Asia'],
    largestCity: "Sana'a",
    name: 'Yemen',
    secondLargestCity: 'Aden',
  },
  {
    capital: 'Lusaka',
    continents: ['Africa'],
    largestCity: 'Lusaka',
    name: 'Zambia',
    secondLargestCity: 'Kitwe',
  },
  {
    capital: 'Harare',
    continents: ['Africa'],
    largestCity: 'Harare',
    name: 'Zimbabwe',
    secondLargestCity: 'Bulawayo',
  },
];
